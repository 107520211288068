import { httpServer as http } from "./Settings/const.js";

// localhost
export const httpServer = http;

// prodaction
// export const httpServer = "https://admin.musictherapy.by";

// herocku
// export const httpServer = "https://musictherapy96.herokuapp.com";
