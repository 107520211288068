export const Tools = [
    {label: 'Рояль', value: 'Рояль'},
    {label: 'Флейта', value: 'Флейта'},
    {label: 'Виолончель', value: 'Виолончель'},
    {label: 'Скрипка', value: 'Скрипка'},
    {label: 'Вокал', value: 'Вокал'},
    {label: 'Арфа', value: 'Арфа'},
    {label: 'Клавесин', value: 'Клавесин'},
    {label: 'Орган', value: 'Орган'},
    {label: 'Гонг', value: 'Гонг'},
    {label: 'Тибетские поющие чаши', value: 'Тибетские поющие чаши'},
    {label: 'Караталы', value: 'Караталы'},
    {label: 'Чакрофоны', value: 'Чакрофоны'},
    {label: 'Шум дождя', value: 'Шум дождя'},
    {label: 'Шум ручья', value: 'Шум ручья'},
    {label: 'Шум океана', value: 'Шум океана'},
    {label: 'Калимба', value: 'Калимба'},
    {label: 'Глюкофон', value: 'Глюкофон'},
    {label: 'Барчаймс', value: 'Барчаймс'},
    {label: 'Колокольчики Коши', value: 'Колокольчики Коши'},
    {label: 'Колокольчики Нада', value: 'Колокольчики Нада'},
    {label: 'Валдайские колокольчики', value: 'Валдайские колокольчики'},
    {label: 'Этническая погремушка', value: 'Этническая погремушка'},
    {label: 'Гитара', value: 'Гитара'},
    {label: 'Контрабас', value: 'Контрабас'},
    {label: 'Симфонический оркестр', value: 'Симфонический оркестр'}
];